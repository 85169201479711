import React, {
    useState,
    useEffect,
    useCallback,
    InputHTMLAttributes,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';

type ToggleInputProps = {
    options?: { isUnique?: boolean } & InputHTMLAttributes<HTMLInputElement>;
    formRegistering?: UseFormReturn<{
        [key: string]: string | boolean | number;
    }>;
};

const ToggleInput = ({
    formRegistering,
    options = { id: generateUniqueID() },
}: ToggleInputProps): JSX.Element => {
    const { id, onChange, name, defaultChecked, isUnique } = options;
    const [uniqueIdPart] = useState(isUnique ? `_${generateUniqueID()}` : '');
    const formValue = formRegistering && id && formRegistering.getValues()[id];

    const [state, setState] = useState<boolean>(defaultChecked ?? false);

    useEffect(() => {
        if (id && formRegistering) {
            setState(formRegistering.getValues()[id] as boolean);
        }
    }, [formValue, setState, id, formRegistering]);

    const changeHandler = useCallback(
        (event) => {
            setState(!state);
            if (onChange) onChange(event);
            if (formRegistering?.setValue && id)
                formRegistering.setValue(id, !state);
        },
        [formRegistering, id, onChange, state]
    );

    return (
        <label htmlFor={`${id}${uniqueIdPart}`}>
            <input
                id={`${id}${uniqueIdPart}`}
                name={name}
                type="checkbox"
                className="hidden"
                checked={state}
                onChange={changeHandler}
            />
            <div
                className={`cursor-pointer flex flex-wrap content-center h-4 w-8 rounded-full px-1 ${
                    state
                        ? 'bg-awos-navy-2 dark:bg-awos-white-3'
                        : 'bg-awos-grayscale-38 dark:bg-awos-grayscale-92'
                }`}
            >
                <div
                    className={`duration-300 ease-in-out h-2.5 w-2.5 rounded-full bg-white ${
                        state
                            ? 'ml-3.5 dark:bg-awos-black-9'
                            : 'dark:bg-awos-white-3'
                    }`}
                />
            </div>
        </label>
    );
};

export default ToggleInput;
