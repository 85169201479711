import {
    REPORT_WINDOW_NAME,
    REPORT_STORAGE_KEY,
    ReportStorageReason
} from 'consts';

type MockLS = {
    getItem(key: string): null | string;
    setItem(key: string, value: string): void;
};

export const MockLocalStorage = (): MockLS => {
    const store: { [key: string]: string } = {};

    return {
        getItem(key: string) {
            return store[key] || null;
        },
        setItem(key: string, value: string) {
            store[key] = value.toString();
        }
    };
};

export const isModalWindow = (): boolean =>
    [REPORT_WINDOW_NAME].includes(window.name);

export const setWithExpiry = (
    key: string,
    value: string,
    ttl: number
): void => {
    const now = new Date();

    const item = {
        value,
        expiry: now.getTime() + ttl * 1000
    };
    localStorage.setItem(key, JSON.stringify(item));
    window.dispatchEvent(new Event('storage'));
};

export const getWithExpiry = (key: string): null | string => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
};

export const setReportNotificationHandlers = (): void => {
    if (isModalWindow()) {
        setWithExpiry(
            REPORT_STORAGE_KEY,
            ReportStorageReason.WINDOW_LOADING,
            5
        );
        window.onbeforeunload = () => {
            window.localStorage.removeItem(REPORT_STORAGE_KEY);
        };
    }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const safelyParseJSON = (
    json: string | null,
    dafaultValue?: any
): any => {
    if (!json) return dafaultValue || null;
    let parsed = dafaultValue || null;
    try {
        parsed = JSON.parse(json);
    } catch (e) {
        // ignore error
    }

    return parsed;
};
/* eslint-enable @typescript-eslint/no-explicit-any */
