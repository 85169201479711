import React, { FC } from 'react';
import ReactModal from 'react-modal';

import { Button } from 'pages/generic/form/Button';
import BasicWindowContainer from 'pages/generic/BasicWindowContainer';
import {
    PreventNavigation,
    PreventNavigationData,
} from 'pages/generic/preventNavigation';

type LeaveModalProps = {
    shouldPrevent: boolean;
    onSubmit(): Promise<boolean>;
};

const borderColor =
    'dark:border-c-modal-dark-border border-c-modal-light-border';

const styles = {
    modalText: `p-4 border border-t-0 border-b-0 ${borderColor}`,
    windowContainerContainer: 'overflow-auto w-[400px] sm:w-[470px] rounded',
    modal: 'center relative text-awos-navy-2 focus:outline-none dark:text-awos-white-3',
    windowContainerTopLeft:
        'text-awos-navy-2 dark:text-c-basicWindowContainer-dark-sideText',
    buttonWrapper: `pb-4 px-4 flex justify-between border border-t-0 rounded-b ${borderColor}`,
    windowContainerTopBar: `bg-awos-white-2 text-white dark:bg-c-basicWindowContainer-dark-topBar border ${borderColor} `,
    overlay: `fixed flex items-center justify-center z-[1500] inset-0 bg-awos-black-10 bg-opacity-50 overflow-auto dark:bg-opacity-75`,
};

export const LeaveModal: FC<LeaveModalProps> = ({
    onSubmit,
    shouldPrevent,
}) => {
    return (
        <PreventNavigation shouldPrevent={shouldPrevent}>
            {(preventNavigationData: PreventNavigationData) => {
                const {
                    proceedToPreventedLocation,
                    preventedLocation,
                    setPreventedLocation,
                } = preventNavigationData;

                const skipPreventing = () => setPreventedLocation('');

                const submitHandler = async () => {
                    const isSuccesedSubmit = await onSubmit();
                    if (isSuccesedSubmit) proceedToPreventedLocation();
                    if (!isSuccesedSubmit) skipPreventing();
                };

                return (
                    <ReactModal
                        appElement={document.body}
                        isOpen={!!preventedLocation}
                        overlayClassName={styles.overlay}
                        className={styles.modal}
                    >
                        <BasicWindowContainer
                            containerWithInputs
                            styles={{
                                topBar: styles.windowContainerTopBar,
                                topLeft: styles.windowContainerTopLeft,
                                container: styles.windowContainerContainer,
                            }}
                            topLeft={
                                <span className="inline font-semibold text-lg">
                                    CONFIRM NAVIGATION
                                </span>
                            }
                        >
                            <div className={styles.modalText}>
                                <p>Are you sure you want to leave this page?</p>
                                <p>
                                    You have some unsaved changes in your
                                    settings.
                                </p>
                            </div>
                            <div className={styles.buttonWrapper}>
                                <Button
                                    primary
                                    testId="discard"
                                    iconName="decline"
                                    onClick={proceedToPreventedLocation}
                                >
                                    DISCARD
                                </Button>
                                <div className="flex">
                                    <Button
                                        primary
                                        testId="cancel"
                                        className="mr-4"
                                        onClick={skipPreventing}
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        primary
                                        testId="submit"
                                        iconName="accept"
                                        onClick={submitHandler}
                                    >
                                        SAVE
                                    </Button>
                                </div>
                            </div>
                        </BasicWindowContainer>
                    </ReactModal>
                );
            }}
        </PreventNavigation>
    );
};
