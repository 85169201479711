import React, { createContext, useContext } from 'react';
import { useGetData } from 'tools/hooks/getData';

export type AppSettings = {
    speci_enabled: boolean;
};

export type AppSettingsContextProps = {
    isLoading: boolean;
    data?: AppSettings;
    fetchSettings: () => void;
};

const defaultContext: AppSettingsContextProps = {
    isLoading: false,
    data: undefined,
    fetchSettings: () => {},
};

const AppSettingsContext =
    createContext<AppSettingsContextProps>(defaultContext);

type AppSettingsProviderProps = {
    children: JSX.Element | JSX.Element[];
    initialValue?: AppSettings;
};

export const AppSettingsProvider = ({
    children,
    initialValue,
}: AppSettingsProviderProps): JSX.Element => {
    const { data, refetch, isLoading } = useGetData<AppSettings | undefined>({
        initialValue,
        retry: true,
        enabled: true,
        endpoint: '/settings/app-settings',
        queryName: 'appSettings',
    });

    return (
        <AppSettingsContext.Provider
            value={{
                isLoading,
                data,
                fetchSettings: refetch,
            }}
        >
            {children}
        </AppSettingsContext.Provider>
    );
};

export const useAppSettings = (): AppSettingsContextProps =>
    useContext(AppSettingsContext);
