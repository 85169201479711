import React, { FC } from 'react';
import { UseFormReturn, UseFormRegisterReturn } from 'react-hook-form';

type RadioInputProps = {
    id: string;
    label: string;
    value: string;
    name?: string;
    onChange?(): void;
    formRegistering?: UseFormReturn<{
        [key: string]: string;
    }>;
};

export const RadioInput: FC<RadioInputProps> = ({
    id,
    name,
    label,
    value,
    onChange,
    formRegistering,
}: RadioInputProps) => {
    let registeredField: UseFormRegisterReturn | undefined;

    if (name && formRegistering) {
        registeredField = formRegistering.register(name);
    }

    const changeHandler = () => {
        if (onChange) onChange();
        if (formRegistering && name) {
            formRegistering.setValue(name, value);
        }
    };

    return (
        <div className="inline-flex items-center mr-11">
            <label
                htmlFor={id}
                className="flex items-center cursor-pointer h-5"
            >
                <input
                    id={id}
                    type="radio"
                    name={name}
                    value={value}
                    className="hidden"
                    onChange={changeHandler}
                    ref={registeredField?.ref}
                />
                <span
                    className="w-4 h-4 inline-block mr-2 rounded-full
                    border border-c-radio-light-border dark:border-c-radio-dark-border"
                />
                {label}
            </label>
        </div>
    );
};
