interface RequestTimeoutInit extends RequestInit {
    timeout?: number;
}

const throwOnTimeout = (
    timeout: number,
    controller: AbortController
): Promise<never> =>
    new Promise((_, reject) =>
        setTimeout(() => {
            controller.abort();
            reject();
        }, timeout)
    );

const fetchWithTimeout = (
    resource: RequestInfo,
    options: RequestTimeoutInit = { timeout: 2000 }
): Promise<Response> => {
    const { timeout, ...remainingOptions } = options;
    const controller = new AbortController();
    const { signal } = controller;

    if (timeout) {
        return Promise.race([
            fetch(resource, { ...remainingOptions, signal }),
            throwOnTimeout(timeout, controller),
        ]);
    }
    return fetch(resource, remainingOptions);
};

export default fetchWithTimeout;
