import React, { FC } from 'react';

import { ReportType } from 'consts';

import { Form } from '../forms';
import { ENLARGED_FEATURE_ON } from '../consts';
import { metSchema } from '../forms/ReportSchemas';
import { ReportModalConfiguration } from '../types';
import { ReportNotificationMessage } from '../forms/components';

type SpecialReportProps = {
    onReportDiscard: () => void;
    config: ReportModalConfiguration;
};

export const SpecialReport: FC<SpecialReportProps> = ({
    config,
    onReportDiscard,
}) => (
    <>
        <ReportNotificationMessage message={config.data} />
        <div className="flex-full-height">
            <Form
                key="met"
                formSchema={metSchema}
                reportType={ReportType.MET}
                highlightedFields={config.fields}
                closeFormHandler={onReportDiscard}
                special={{ ...ENLARGED_FEATURE_ON, visible: false }}
            />
        </div>
    </>
);
