import classNames from 'classnames';
import Clock from 'react-live-clock';
import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/material';

import { DarkMode } from 'types';
import { isModalWindow } from 'helpers';
import SVGIcon from 'assets/icons/SVGIcon';
import { useGetData } from 'tools/hooks/getData';
import { ReactComponent as BarsSVG } from 'assets/icons/svg/Menu.svg';
import { ReactComponent as SunSVG } from 'assets/icons/svg/light_mode.svg';
import { ReactComponent as MoonSVG } from 'assets/icons/svg/Dark mode.svg';
import { ReactComponent as AirotecLogo } from 'assets/icons/svg/airotec.svg';
import { ReactComponent as ExpandSVG } from 'assets/icons/svg/Fullscreen.svg';

import { SideMenu } from '../sideMenu';
import { ActiveRunway } from './ActiveRunway';
import statusbarStyles from './status-bar.module.css';

const toggleFullscreenMode = () => {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
        document.exitFullscreen();
    }
};

type StatusBarProps = {
    darkMode: DarkMode;
    setDarkMode: (darkMode: DarkMode) => void;
};

const StatusBar: React.FunctionComponent<StatusBarProps> = ({
    darkMode,
    setDarkMode,
}: StatusBarProps) => {
    if (!isModalWindow()) {
        const [sideMenuShown, setSideMenuShown] = useState(false); // eslint-disable-line
        // eslint-disable-next-line
        const { data: isOnline } = useGetData({
            enabled: true,
            initialValue: false,
            endpoint: '/health',
            queryName: 'health',
            intervalMs: 1000,
            clearOnError: true,
        });

        // eslint-disable-next-line
        const { data: airportName } = useGetData<{ name: string } | undefined>({
            enabled: true,
            endpoint: '/settings/airport-name',
            queryName: 'airportName',
            initialValue: undefined,
            intervalMs: 1000,
        });

        const styles = {
            container: `fixed z-[60] bg-c-statusbar-light dark:bg-c-statusbar-dark`,
            nav: 'flex flex-wrap content-center w-screen items-center justify-between px-6 sm:h-16 z-10 text-base md:text-lg text-awos-navy-2 border-b dark:text-awos-white-3 dark:border-awos-grayscale-46',
            statusBox: `${
                isOnline ? 'bg-awos-green-1' : 'bg-awos-red-1'
            } text-white align-middle dark:text-awos-gray-15 rounded text-xs py-0.5 px-2 ml-2`,
            timeAndPingDiv: 'flex w-60 text-center ml-3 md:ml-8 h-full',
            time: 'flex flex-inline items-center justify-center w-56',
            pingDotSpan: 'flex items-center',
            pingDotHalo: `animate-awos_status_ping absolute inline-flex h-3 w-3 rounded-full ${
                isOnline ? 'bg-awos-green-1' : 'bg-awos-red-1'
            }`,
            pingDot: `relative inline-flex rounded-full h-3 w-3 ${
                isOnline ? 'bg-awos-green-1' : 'bg-awos-red-1'
            }`,
        };

        return (
            <ClickAwayListener onClickAway={() => setSideMenuShown(false)}>
                <div className={styles.container}>
                    <nav
                        className={classNames(
                            styles.nav,
                            statusbarStyles.navigation
                        )}
                    >
                        <div
                            data-testid="side-menu+logo"
                            className={classNames(
                                'flex',
                                statusbarStyles.appLogo
                            )}
                        >
                            <button
                                data-testid="side-menu-button"
                                className="py-1 px-2 mr-5"
                                type="button"
                                onClick={() =>
                                    setSideMenuShown((prev) => !prev)
                                }
                            >
                                <SVGIcon
                                    lightIcon={<BarsSVG className="w-5 h-3" />}
                                />
                            </button>
                            <SVGIcon
                                lightIcon={
                                    <AirotecLogo
                                        className="h-10"
                                        aria-hidden="true"
                                        focusable={false}
                                        role="img"
                                    />
                                }
                            />
                        </div>
                        <div className="md:w-10 lg:w-24"> </div>
                        <div
                            className={classNames(
                                'items-center',
                                statusbarStyles.appName
                            )}
                        >
                            <h3>{airportName?.name ?? 'AWOS'}</h3>
                        </div>
                        <div className="flex flex-wrap items-center justify-between h-9">
                            <ActiveRunway />
                            <p className="flex">
                                <span className={styles.statusBox}>
                                    {isOnline ? 'NORMAL' : 'ABNORMAL'}
                                </span>
                            </p>
                            <div className={styles.timeAndPingDiv}>
                                <p className={styles.time}>
                                    <Clock
                                        format="YYYY-MM-DD HH:mm:ss [UTC]"
                                        ticking={Boolean(true)}
                                        interval={500}
                                        timezone="UTC"
                                    />
                                </p>

                                <span className={styles.pingDotSpan}>
                                    <span className={styles.pingDot} />
                                    <span className={styles.pingDotHalo} />
                                </span>
                            </div>

                            <button
                                type="button"
                                data-testid="dark-mode-switcher"
                                onClick={() =>
                                    setDarkMode(
                                        darkMode === 'dark' ? 'light' : 'dark'
                                    )
                                }
                                className="ml-2.5 h-full w-9 items-center justify-center"
                            >
                                <SVGIcon
                                    lightIcon={<MoonSVG className="m-2.5" />}
                                    darkIcon={<SunSVG className="m-2.5" />}
                                    darkMode={darkMode === 'dark'}
                                />
                            </button>
                            <button
                                type="button"
                                onClick={() => toggleFullscreenMode()}
                                className="flex ml-2.5 h-full w-9 items-center justify-center"
                            >
                                <SVGIcon lightIcon={<ExpandSVG />} />
                            </button>
                        </div>
                    </nav>
                    <div>
                        <SideMenu showSideMenu={sideMenuShown} />
                    </div>
                </div>
            </ClickAwayListener>
        );
    }

    return null;
};

export { StatusBar };
