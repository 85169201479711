import moment from 'moment';
import { get } from 'lodash';
import React, { ReactElement } from 'react';

import { WSNotification } from 'types';
import { ReportType, NotificationReason } from 'consts';
import { SoundNotification } from 'pages/generic/soundNotification';

import { ReportModalConfiguration } from './types';
import { DEFAULT_REPORT_MODAL_CONFIG, TIME_FORMAT } from './consts';

export const transpileMessageData = (
    eventData: WSNotification
): ReportModalConfiguration => {
    const reportModalConfig = { ...DEFAULT_REPORT_MODAL_CONFIG };
    if (eventData.type === NotificationReason.REPORT_SPECIAL) {
        reportModalConfig.notificationReason =
            NotificationReason.REPORT_SPECIAL;
        reportModalConfig.special = true;
        reportModalConfig.reportType = ReportType.MET;
        reportModalConfig.reportPopupTitle = 'SPECIAL';
        reportModalConfig.notificationTitle = 'New SPECIAL report';
        reportModalConfig.data = get(eventData, 'data.reason', '');
        reportModalConfig.fields = get(eventData, 'data.fields', []);
    }

    if (eventData.type === NotificationReason.REPORT_SPECI) {
        reportModalConfig.notificationReason = NotificationReason.REPORT_SPECI;
        reportModalConfig.speci = true;
        reportModalConfig.reportType = ReportType.METAR;
        reportModalConfig.reportPopupTitle = 'SPECI';
        reportModalConfig.notificationTitle = 'New SPECI report';
        reportModalConfig.data = get(eventData, 'data.reason', '');
        reportModalConfig.fields = get(eventData, 'data.fields', []);
    }

    if (eventData.type === NotificationReason.REPORT_SCHEDULED) {
        reportModalConfig.notificationReason =
            NotificationReason.REPORT_SCHEDULED;
        const type = get(eventData, 'data.report_type', ReportType.METAR).split(
            ' '
        )[0];
        reportModalConfig.reportType = type as ReportType;
        reportModalConfig.reportPopupTitle = type;
        reportModalConfig.notificationTitle = `New ${type} report`;
        reportModalConfig.data = get(eventData, 'data.message', '');
    }

    if (eventData.type === NotificationReason.REPORT_CREATED) {
        reportModalConfig.notificationReason =
            NotificationReason.REPORT_CREATED;
        const type = get(eventData, 'data.ReportType', '').split(' ')[0];
        const reportTime = get(eventData, 'data.Time', '');
        reportModalConfig.reportType = null;
        reportModalConfig.notificationTitle = `New ${type} report (${moment
            .utc(reportTime)
            .format(TIME_FORMAT)} UTC)`;
    }

    return reportModalConfig;
};

type GetSnackContent = (args: {
    webSocketData: ReportModalConfiguration;
}) => ReactElement;
export const getSnackContent: GetSnackContent = ({
    webSocketData: { notificationTitle },
}) => (
    <SoundNotification>
        <div
            role="alert"
            className="p-3 rounded bg-awos-black-1 text-awos-gray-1 text-sm shadow-md dark:bg-awos-white-3 dark:text-awos-gray-15"
        >
            <span>{notificationTitle}</span>
            <span className="muted-notification" />
        </div>
    </SoundNotification>
);
