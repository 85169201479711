import React, { createContext, useContext, useState, useEffect } from 'react';

import { DarkMode } from 'types';

export type DarkModeContextProps = {
    darkMode: DarkMode;
    setDarkMode(mode: DarkMode): void;
};

const defaultContext: DarkModeContextProps = {
    darkMode: 'light',
    setDarkMode() {},
};

const DarkModeContext = createContext<DarkModeContextProps>(defaultContext);

type DarkModeContextProviderProps = {
    children: JSX.Element | JSX.Element[];
};

export const DarkModeContextProvider = ({
    children,
}: DarkModeContextProviderProps): JSX.Element => {
    const valueFromLS = localStorage.getItem('theme') as DarkMode;
    const initialValue = valueFromLS || 'light';
    const [darkMode, setDarkMode] = useState<DarkMode>(initialValue);

    if (!valueFromLS) {
        localStorage.setItem('theme', darkMode);
    }

    useEffect(() => {
        const root = window.document.documentElement;
        if (
            root.classList.contains('dark') ||
            root.classList.contains('light')
        ) {
            root.classList.remove(darkMode === 'dark' ? 'light' : 'dark');
            localStorage.setItem('theme', darkMode);
        }
        root.classList.add(darkMode);
    }, [darkMode]);

    return (
        <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
            {children}
        </DarkModeContext.Provider>
    );
};

export const useDarkMode = (): DarkModeContextProps =>
    useContext(DarkModeContext);
